import axios from 'axios'
import { from } from 'rxjs'

const formatUrl = (apiPath) => {
  let server = '/api/v1'
  if (!process.env.CLIENT) {
    server = `http://localhost:${process.env.CITYWANDERER_PORT}/api/v1`
  }
  return server + apiPath
}

const request = axios.create({
  timeout: 30000,
})

request.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data)
  },
  (error) => {
    if (error.response) {
      // error from server
      return Promise.reject(error.response.data)
    }

    // network error
    return Promise.reject(error)
  },
)

export default ({ url, ...otherConfig }) => from(request({ url: formatUrl(url), ...otherConfig }))
