import { compose, createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { connectRouter } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'

import rootReducer from '@client/reducers'
import rxjsRootReducer from '@client/store/rootReducer'
import rootEpic from '@client/store/rootEpic'

const epicMiddleware = createEpicMiddleware()
const composeEnhancer = process.env.CLIENT
  ? // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose

const devMiddlewares = []
if (process.env.NODE_ENV === 'development' && process.env.CLIENT) {
  devMiddlewares.push()
}

const configureStore = (preloadedState, history, ...middleware) => {
  const store = createStore(
    combineReducers({
      ...rootReducer,
      ...rxjsRootReducer,
      router: connectRouter(history),
    }),
    preloadedState,
    composeEnhancer(applyMiddleware(thunk, epicMiddleware, ...middleware, ...devMiddlewares)),
  )

  epicMiddleware.run(rootEpic)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}

export default configureStore
