import { types } from '@client/actions/admin/mail'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const mail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_ADMIN_SEND_MAIL: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_ADMIN_SEND_MAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default {
  mail,
}
