import { types } from '@client/actions/team'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const team = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_TEAM:
    case types.FETCH_READ_TEAM:
    case types.FETCH_UPDATE_TEAM:
    case types.FETCH_DELETE_TEAM: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_TEAM:
    case types.SET_READ_TEAM:
    case types.SET_UPDATE_TEAM:
    case types.SET_DELETE_TEAM: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}
const teamList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_READ_TEAM_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_TEAM_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_TEAM_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  team,
  teamList,
}
