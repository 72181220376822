import React from 'react'
import { Helmet } from 'react-helmet'
import { string } from 'prop-types'
import { Col, Icon, Row } from 'antd'

import { AppContainer } from '@client/containers/index'

class NoPermission extends React.Component {
  static propTypes = {
    title: string,
  }

  static defaultProps = {
    title: 'Page',
  }

  render() {
    const { title } = this.props

    return (
      <AppContainer style={{ paddingTop: 0 }}>
        <Helmet>
          <title>No Permission</title>
        </Helmet>
        <Row type="flex" justify="center">
          <Col
            span={24}
            style={{
              textAlign: 'center',
              fontSize: '50px',
              backgroundColor: 'white',
              minHeight: 'calc(100vh - 120px)',
              with: '100vw',
              paddingTop: '30vh',
              overflowWrap: 'break-word',
            }}
          >
            <Icon type="frown" />
            <br />
            No Permission: {title}
          </Col>
        </Row>
      </AppContainer>
    )
  }
}

export default NoPermission
