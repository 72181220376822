import { errorMessages } from '@server/constants/errors'

import { types } from '../actions/error'

const INIT_STATE = {
  data: {},
}
const error = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.ERROR_OCCURRED: {
      return {
        ...state,
        data: (() => {
          switch (action.payload.code) {
            case errorMessages.CHECK_AUTHENTICATION_FAILED.data.code:
            case errorMessages.CANCEL_REQUEST.data.code: {
              return { ...state }
            }
            default:
              return action.payload
          }
        })(),
      }
    }

    case types.RESET_ERROR: {
      return {
        ...state,
        ...INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default { error }
