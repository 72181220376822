import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_USER: 'FETCH_CREATE_USER',
  SET_CREATE_USER: 'SET_CREATE_USER',

  FETCH_READ_USER: 'FETCH_READ_USER',
  SET_READ_USER: 'SET_READ_USER',

  FETCH_UPDATE_USER: 'FETCH_UPDATE_USER',
  SET_UPDATE_USER: 'SET_UPDATE_USER',

  FETCH_DELETE_USER: 'FETCH_DELETE_USER',
  SET_DELETE_USER: 'SET_DELETE_USER',

  FETCH_CHECK_USER_EMAIL: 'FETCH_CHECK_USER_EMAIL',
  SET_CHECK_USER_EMAIL: 'SET_CHECK_USER_EMAIL',

  FETCH_READ_USER_LIST: 'FETCH_READ_USER_LIST',
  SET_READ_USER_LIST: 'SET_READ_USER_LIST',

  RESET_USER: 'RESET_USER',
  RESET_USER_LIST: 'RESET_USER_LIST',
}

export default {
  addUser: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_USER })
    return request.post({ url: '/user', type: types.SET_CREATE_USER, dispatch, data })
  },

  getUser: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_USER })
    return request.get({ url: `/user/${id}`, type: types.SET_READ_USER, dispatch })
  },

  updateUser: (id, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_USER })
    return request.patch({ url: `/user/${id}`, type: types.SET_UPDATE_USER, dispatch, data })
  },

  deleteUser: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_USER })
    return request.delete({ url: `/user/${id}`, type: types.SET_DELETE_USER, dispatch })
  },

  getUserList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_USER_LIST })
    return request.get({ url: '/user', type: types.SET_READ_USER_LIST, dispatch, params })
  },

  checkUserEmail: (competitionId, hostId, email) => (dispatch) => {
    dispatch({ type: types.FETCH_CHECK_USER_EMAIL })
    return request.post({
      url: `/user/checkEmail`,
      type: types.SET_CHECK_USER_EMAIL,
      dispatch,
      data: { competitionId, hostId, email },
    })
  },

  resetUser: () => (dispatch) => {
    return dispatch({ type: types.RESET_USER })
  },

  restUserList: () => (dispatch) => {
    return dispatch({ type: types.RESET_USER_LIST })
  },
}
