import { types } from '@client/actions/auth'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
  isLogin: false,
}

const auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CHECK_LOGIN:
    case types.FETCH_LOGIN:
    case types.FETCH_UPDATE_PASSWORD:
    case types.FETCH_FORREAD_PASSWORD:
    case types.FETCH_LOGOUT: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CHECK_LOGIN:
    case types.SET_LOGIN: {
      return {
        ...state,
        ...action.payload,
        isLogin: action.payload.loadingStatus === loadingStatus.SUCCESS,
      }
    }

    case types.SET_UPDATE_PASSWORD:
    case types.SET_FORREAD_PASSWORD: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.SET_LOGOUT: {
      return {
        ...state,
        ...INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default { auth }
