import React from 'react'
import { Icon } from 'antd'

import iconImage from '@client/images/icon.png'

import packageJson from '../../../package.json'
import styles from './Footer.styl'

const Footer = () => {
  return (
    <div className={styles.footer}>
      {/* icon */}
      <div className={styles.icon}>
        <img src={iconImage} />
      </div>

      <div>
        Copyright <Icon type="copyright" /> Citywanderer 2019
        <span className={styles.version}>v{packageJson.version}</span>
      </div>
    </div>
  )
}

export default Footer
