import React from 'react'
import { bool, number, string } from 'prop-types'
import { Icon, Progress } from 'antd'

import styles from './LoadingMask.styl'

class LoadingMask extends React.Component {
  static propTypes = {
    showProgress: bool,
    visible: bool.isRequired,
    progress: number,
    title: string,
  }

  static defaultProps = {
    title: 'Loading',
    showProgress: false,
    progress: 0,
  }

  state = {
    globalProgress: 0,
  }

  componentDidUpdate(prevProps, prevState) {
    const { visible } = this.props
    if (visible !== prevProps.visible && visible) {
      if (this.topProgressInterval) return
      this.topProgressInterval = setInterval(() => {
        const { globalProgress } = this.state
        this.setState({ globalProgress: globalProgress + 0.5 })
      }, 200)
    }
    if (visible !== prevProps.visible && !visible) {
      clearInterval(this.topProgressInterval)
      this.topProgressInterval = null
    }
  }

  render() {
    const { visible, progress, showProgress, title } = this.props

    if (!visible) return null

    return (
      <div className={styles.loadingMask}>
        {/* loading animation */}
        <Icon
          type="loading"
          spin
          style={{
            fontSize: 28,
            color: '#71cdf4',
          }}
        />

        {/* progress bar */}
        {showProgress && (
          <Progress
            percent={progress}
            showInfo={false}
            status="active"
            style={{
              width: '150px',
              paddingTop: '10px',
            }}
          />
        )}

        {/* title */}
        <p
          style={{
            fontSize: 12,
            color: '#71cdf4',
            padding: '5px',
          }}
        >
          {title}
        </p>

        {/* <Progress
          className={styles.progressBar}
          strokeLinecap="square"
          strokeWidth={2}
          percent={this.state.globalProgress}
          showInfo={false}
        /> */}
      </div>
    )
  }
}

export default LoadingMask
