import axios from 'axios'

import actions from '@client/actions'
import { types as UploadTypes } from '@client/actions/upload'
import * as loadingStatus from '@client/constants/loadingStatus'

const methods = ['get', 'post', 'patch', 'delete', 'put']

const formatUrl = (apiPath) => {
  let server = '/api/v1'
  if (!process.env.CLIENT) {
    server = `http://localhost:${process.env.CITYWANDERER_PORT}/api/v1`
  }
  return server + apiPath
}

class ApiClient {
  constructor(req) {
    const SELF = this
    const { CancelToken } = axios
    const source = CancelToken.source()
    this.cancel = source.cancel

    methods.forEach((method) => {
      SELF[method] = ({
        url,
        rawUrl,
        params,
        data,
        headers,
        dispatch,
        type,
        onUploadProgress,
        onDownloadProgress,
      } = {}) => {
        actions.increaseRequest()(dispatch)

        return axios({
          baseURL: rawUrl || formatUrl(url),
          method,
          params,
          data,
          headers,
          cancelToken: source.token,
          onUploadProgress,
          onDownloadProgress,
        })
          .then((res) => {
            const result = {
              type,
              payload: {
                data: res.data || {},
                loadingStatus: loadingStatus.SUCCESS,
                done: true,
              },
            }
            if (Array.isArray(res.data)) {
              result.payload.total = res.data.length > 0 ? res.data[0].total : 0
            }
            if (type === UploadTypes.SET_UPLOAD_TO_S3) {
              result.payload.data.url = url
            }

            dispatch(result)
            return Promise.resolve(result)
          })
          .catch((error) => {
            // error.response => normal request error response
            // error.message  => client request cancel error
            const { response: { data: responseData } = {}, message } = error
            const err = responseData || message
            const result = {
              type,
              payload: {
                error: err,
                loadingStatus: loadingStatus.FAILED,
                done: true,
              },
            }
            dispatch(result)
            actions.setError(err)(dispatch)
          })
          .finally(() => {
            actions.decreaseRequest()(dispatch)
          })
      }
    })
  }
}

export default new ApiClient()
