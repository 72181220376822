export const TASK_CATEGORIES = ['盤點', '探索', '評估', '轉彎']

export const DREAM_FIGHTER_TASK_CATEGORIES = [
  '必修任務',
  '其他選修',
  '社會關懷',
  '環境保護',
  '永續資源',
  '地方創生',
]

export const CATEGORY_ABILITIES = {
  [TASK_CATEGORIES[0]]: ['覺察力', '自我發展力'],
  [TASK_CATEGORIES[1]]: ['突破力', '恆毅力'],
  [TASK_CATEGORIES[2]]: ['連結支持力', '溝通理解力'],
  [TASK_CATEGORIES[3]]: ['同理力', '公民責任感'],

  [DREAM_FIGHTER_TASK_CATEGORIES[0]]: ['評分'],
  [DREAM_FIGHTER_TASK_CATEGORIES[1]]: ['評分'],
  [DREAM_FIGHTER_TASK_CATEGORIES[2]]: ['評分'],
  [DREAM_FIGHTER_TASK_CATEGORIES[3]]: ['評分'],
  [DREAM_FIGHTER_TASK_CATEGORIES[4]]: ['評分'],
  [DREAM_FIGHTER_TASK_CATEGORIES[5]]: ['評分'],
}

export const IMAGE_RATIOS = {
  TASK: 16 / 9,
  HOST: 1,
  PROFILE: 1,
  TEAM: 16 / 9,
  STORY: 16 / 9,
  COMPETITION: 16 / 9,
  ABOUTME_P1: 3 / 4,
  ABOUTME_P2: 4 / 3,
}
