import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_SIGNED_URL: 'FETCH_CREATE_SIGNED_URL',
  SET_CREATE_SIGNED_URL: 'SET_CREATE_SIGNED_URL',
}

export default {
  getSignedUrl: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_SIGNED_URL })
    return request.post({
      url: '/aws/signedUrl',
      type: types.SET_CREATE_SIGNED_URL,
      dispatch,
      data,
    })
  },
}
