/* eslint-disable import/first */
import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { hot } from 'react-hot-loader'
import PropTypes from 'prop-types'
import figlet from 'figlet'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'

require('antd/dist/antd.less')
require('react-quill/dist/quill.core.css')
require('react-quill/dist/quill.snow.css')

import { NotFound, NoPermission, LoadingMask } from '@client/components/index'
import { ErrorModal } from '@client/containers/index'

import actions from '@client/actions'
import routes from '@client/routes'
import * as sampleActions from '@client/store/sample/action'

import styles from './App.styl'
import packageJson from '../package.json'

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    sampleActison: bindActionCreators(sampleActions, dispatch),
  }
}
const mapStateToProps = (state) => ({
  requestCount: state.requestCount,
  loadingMask: state.loadingMask,
  auth: state.auth.data,
})

class App extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    requestCount: PropTypes.object.isRequired,
    loadingMask: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    isFontLoaded: false,
  }

  componentDidMount() {
    figlet.defaults({ fontPath: '/fonts' })
    const option = {
      font: 'small',
      horizontalLayout: 'fitted',
    }
    figlet(`${packageJson.author}\nv${packageJson.version}`, option, (error, data) => {
      if (!error) console.log(data)
    })
    if (!window.SSR) {
      this.props.actions.checkLogin()
    }
    this.props.actions.getNavigatorCompetitionList()
    // this.props.sampleActions.fetchSampleRequest()
  }

  render() {
    const { auth, requestCount, loadingMask, t } = this.props
    const metaDescription = t(
      '鼓勵人們翹掉不滿意的生活，把城市當成教室，找到想花一輩子追尋的熱情，成為讓別人快樂的存在。',
    )
    const title = t('首頁')
    return (
      <div className={`${styles.citywanderer} ${this.state.isFontLoaded ? styles.fontLoaded : ''}`}>
        <Helmet>
          <title>{title}</title>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
          <meta name="description" content={metaDescription} />
          <meta property="og:url" content="https://citywanderer.org" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta
            property="og:image"
            content="https://s3-ap-northeast-1.amazonaws.com/citywanderer/banner.png"
          />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:locale" content="zh-Hant-TW" />
        </Helmet>
        <Switch>
          {routes.map((route) => {
            if (route.validRoles && !_.includes(route.validRoles, auth.role)) {
              return (
                <Route
                  key={route.path}
                  {...route}
                  component={() => <NoPermission title={route.path} />}
                />
              )
            }
            return <Route key={route.path} {...route} />
          })}
          <Route component={NotFound} status={404} />
        </Switch>
        <ErrorModal />
        <LoadingMask visible={loadingMask.data.isEnable && requestCount.data > 0} />
      </div>
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  hot(module),
)(App)
