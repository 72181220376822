import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { BrowserRouter } from 'react-router-dom'
import FontFaceObserver from 'fontfaceobserver'
import { I18nextProvider } from 'react-i18next'
import { loadableReady } from '@loadable/component'

import i18n from '@client/i18n'
import config from '@server/conf/config'

import App from './App'
import configureStore from './store/configureStore'

const preloadedState = window.__PRELOADED_STATE__
const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const store = configureStore(preloadedState, history, routeMiddleware)

new FontFaceObserver('dfyuanw5')
  .load(null, 30000)
  .then(
    () => {
      document.body.classList.add('fontLoaded')
    },
    () => {
      document.body.classList.remove('fontLoaded')
    },
  )
  .catch(() => {
    console.warn('Failed to load font')
  })

const render = (Component) => {
  const renderMethod = !config.enableIsomorphic ? ReactDOM.render : ReactDOM.hydrate
  renderMethod(
    <BrowserRouter>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <I18nextProvider i18n={i18n}>
            <Component />
          </I18nextProvider>
        </ConnectedRouter>
      </Provider>
    </BrowserRouter>,

    document.getElementById('app'),
  )
}
loadableReady(() => render(App))

export default store
