import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_NAVIGATOR_HOST: 'FETCH_CREATE_NAVIGATOR_HOST',
  SET_CREATE_NAVIGATOR_HOST: 'SET_CREATE_NAVIGATOR_HOST',

  FETCH_READ_NAVIGATOR_HOST: 'FETCH_READ_NAVIGATOR_HOST',
  SET_READ_NAVIGATOR_HOST: 'SET_READ_NAVIGATOR_HOST',

  FETCH_UPDATE_NAVIGATOR_HOST: 'FETCH_UPDATE_NAVIGATOR_HOST',
  SET_UPDATE_NAVIGATOR_HOST: 'SET_UPDATE_NAVIGATOR_HOST',

  FETCH_DELETE_NAVIGATOR_HOST: 'FETCH_DELETE_NAVIGATOR_HOST',
  SET_DELETE_NAVIGATOR_HOST: 'SET_DELETE_NAVIGATOR_HOST',

  FETCH_READ_NAVIGATOR_HOST_LIST: 'FETCH_READ_NAVIGATOR_HOST_LIST',
  SET_READ_NAVIGATOR_HOST_LIST: 'SET_READ_NAVIGATOR_HOST_LIST',

  RESET_NAVIGATOR_HOST: 'RESET_NAVIGATOR_HOST',
  RESET_NAVIGATOR_HOST_LIST: 'RESET_NAVIGATOR_HOST_LIST',
}

export default {
  addNavigatorHost: (competitionId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_NAVIGATOR_HOST })
    return request.post({
      url: `/competition/${competitionId}/host`,
      type: types.SET_CREATE_NAVIGATOR_HOST,
      dispatch,
      data,
    })
  },

  getNavigatorHost: (competitionId, hostId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_NAVIGATOR_HOST })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}`,
      type: types.SET_READ_NAVIGATOR_HOST,
      dispatch,
    })
  },

  updateNavigatorHost: (competitionId, hostId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_NAVIGATOR_HOST })
    return request.patch({
      url: `/competition/${competitionId}/host/${hostId}`,
      type: types.SET_UPDATE_NAVIGATOR_HOST,
      dispatch,
      data,
    })
  },

  deleteNavigatorHost: (competitionId, hostId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_NAVIGATOR_HOST })
    return request.delete({
      url: `/competition/${competitionId}/host/${hostId}`,
      type: types.SET_DELETE_NAVIGATOR_HOST,
      dispatch,
    })
  },

  getNavigatorHostList: (competitionId, params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_NAVIGATOR_HOST_LIST })
    return request.get({
      url: `/competition/${competitionId}/host`,
      type: types.SET_READ_NAVIGATOR_HOST_LIST,
      dispatch,
      params,
    })
  },

  resetNavigatorHost: () => (dispatch) => {
    return dispatch({ type: types.RESET_NAVIGATOR_HOST })
  },

  restNavigatorHostList: () => (dispatch) => {
    return dispatch({ type: types.RESET_NAVIGATOR_HOST_LIST })
  },
}
