import { types } from '@client/actions/admin/user'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_ADMIN_CREATE_USER:
    case types.FETCH_ADMIN_READ_USER:
    case types.FETCH_ADMIN_UPDATE_USER:
    case types.FETCH_ADMIN_DELETE_USER: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_ADMIN_CREATE_USER:
    case types.SET_ADMIN_READ_USER:
    case types.SET_ADMIN_UPDATE_USER:
    case types.EST_ADMIN_DELETE_USER: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
}
const userList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_ADMIN_READ_USER_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }
    case types.SET_ADMIN_READ_USER_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.ADMIN_RESET_USER_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  user,
  userList,
}
