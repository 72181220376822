export const types = {
  SET_LOADING_MASK: 'SET_LOADING_MASK',
}

export default {
  setLoadingMask: ({ type, isEnable }) => (dispatch) => {
    return dispatch({
      type: types.SET_LOADING_MASK,
      payload: {
        data: {
          type,
          isEnable,
        },
      },
    })
  },
}
