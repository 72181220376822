import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_FORM_FORMAT: 'FETCH_CREATE_FORM_FORMAT',
  SET_CREATE_FORM_FORMAT: 'SET_CREATE_FORM_FORMAT',

  FETCH_READ_FORM_FORMAT: 'FETCH_READ_FORM_FORMAT',
  SET_READ_FORM_FORMAT: 'SET_READ_FORM_FORMAT',

  FETCH_UPDATE_FORM_FORMAT: 'FETCH_UPDATE_FORM_FORMAT',
  SET_UPDATE_FORM_FORMAT: 'SET_UPDATE_FORM_FORMAT',

  FETCH_DELETE_FORM_FORMAT: 'FETCH_DELETE_FORM_FORMAT',
  SET_DELETE_FORM_FORMAT: 'SET_DELETE_FORM_FORMAT',

  FETCH_READ_FORM_FORMAT_LIST: 'FETCH_READ_FORM_FORMAT_LIST',
  SET_READ_FORM_FORMAT_LIST: 'SET_READ_FORM_FORMAT_LIST',

  RESET_FORM_FORMAT: 'RESET_FORM_FORMAT',
  RESET_FORM_FORMAT_LIST: 'RESET_FORM_FORMAT_LIST',
}

export default {
  addFormFormat: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_FORM_FORMAT })
    return request.post({
      url: `/formFormat`,
      type: types.SET_CREATE_FORM_FORMAT,
      dispatch,
      data,
    })
  },

  getFormFormat: (formFormatId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_FORM_FORMAT })
    return request.get({
      url: `/formFormat/${formFormatId}`,
      type: types.SET_READ_FORM_FORMAT,
      dispatch,
    })
  },

  updateFormFormat: (formFormatId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_FORM_FORMAT })
    return request
      .patch({
        url: `/formFormat/${formFormatId}`,
        type: types.SET_UPDATE_FORM_FORMAT,
        dispatch,
        data,
      })
      .then((res) => dispatch({ payload: res.data }))
  },

  deleteFormFormat: (formFormatId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_FORM_FORMAT })
    return request.delete({
      url: `/formFormat/${formFormatId}`,
      type: types.SET_DELETE_FORM_FORMAT,
      dispatch,
    })
  },

  getFormFormatList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_FORM_FORMAT_LIST })
    return request.get({
      url: `/formFormat`,
      type: types.SET_READ_FORM_FORMAT_LIST,
      dispatch,
      params,
    })
  },

  resetFormFormat: () => (dispatch) => {
    return dispatch({ type: types.RESET_FORM_FORMAT })
  },

  restFormFormatList: () => (dispatch) => {
    return dispatch({ type: types.RESET_FORM_FORMAT_LIST })
  },
}
