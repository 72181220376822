import { handleActions } from 'redux-actions'
import * as type from './type'
import * as apiState from '@client/constants/apiState'

export const namespace = 'sample'

const INIT_STATE = {
  data: {},
  error: {},
  state: apiState.INIT,
}

export default handleActions(
  {
    [type.FETCH_SAMPLE_REQUEST]: (state, action) => ({
      ...state,
      state: apiState.LOADING,
    }),
    [type.FETCH_SAMPLE_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      state: apiState.SUCCESS,
    }),
    [type.FETCH_SAMPLE_FAILURE]: (state, action) => ({
      ...state,
      error: action.payload,
      state: apiState.FAILURE,
    }),
  },
  INIT_STATE,
)
