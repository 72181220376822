import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from 'antd'
import htmlToText from 'html-to-text'
import { renderToString } from 'react-dom/server'
import { withTranslation } from 'react-i18next'

import styles from './TextShowMore.styl'

const TextShowMore = ({ className, content, length, showMoreText, showLessText, t }) => {
  const i18ShowMoreText = !!showMoreText ? showMoreText : t('顯示更多')
  const i18ShowLessText = !!showLessText ? showLessText : t('顯示更少')
  const pureText = htmlToText.fromString(renderToString(content))
  const isOverflow = pureText.length > length
  const [showMore, setShowMore] = useState(true)
  const displayedText = showMore && isOverflow ? `${pureText.substring(0, length)}...` : content
  return (
    <div className={classnames(styles.textShowMore, className)}>
      {displayedText}
      {isOverflow && (
        <Button
          className={classnames('default', styles.showMoreButton)}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? i18ShowMoreText : i18ShowLessText}
        </Button>
      )}
    </div>
  )
}

TextShowMore.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  content: PropTypes.node,
  length: PropTypes.number,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
}

TextShowMore.defaultProps = {
  className: '',
  content: null,
  length: 50,
  showMoreText: '',
  showLessText: '',
}

export default withTranslation()(TextShowMore)
