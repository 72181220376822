import auth from './auth'
import user from './user'
import story from './story'
import draft from './draft'
import error from './error'
import competition from './competition'
import navigatorCompetition from './navigatorCompetition'
import navigatorHost from './navigatorHost'
import task from './task'
import aws from './aws'
import host from './host'
import admin from './admin'
import team from './team'
import challenge from './challenge'
import time from './time'
import resetPassword from './resetPassword'
import requestCount from './requestCount'
import cancel from './cancel'
import upload from './upload'
import loadingMask from './loadingMask'
import formFormat from './formFormat'

const actions = {
  ...auth,
  ...user,
  ...story,
  ...draft,
  ...error,
  ...competition,
  ...navigatorCompetition,
  ...navigatorHost,
  ...aws,
  ...host,
  ...task,
  ...admin,
  ...team,
  ...challenge,
  ...time,
  ...resetPassword,
  ...requestCount,
  ...cancel,
  ...upload,
  ...loadingMask,
  ...formFormat,
}

export default actions
