import { combineReducers } from 'redux'

import user from './user'
import mail from './mail'

const rootReducer = combineReducers({
  ...user,
  ...mail,
})

export default rootReducer
