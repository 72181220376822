const config = {
  development: {
    GTM: 'GTM-P9MN7T6',
    debugLevel: 'debug',
    jwtSecret: 'citywanderer.development',
    enableIsomorphic: true,
    s3Bucket: 'static-dev.citywanderer.org',
  },
  production: {
    GTM: 'GTM-KFRCKQS',
    debugLevel: 'info',
    jwtSecret: 'citywanderer.production',
    enableIsomorphic: true,
    s3Bucket: 'static.citywanderer.org',
  },
}
// 'development' for webpack.client.config.babel.js
export default config[process.env.NODE_ENV || 'development']
