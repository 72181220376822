import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_TASK: 'FETCH_CREATE_TASK',
  SET_CREATE_TASK: 'SET_CREATE_TASK',

  FETCH_READ_TASK: 'FETCH_READ_TASK',
  SET_READ_TASK: 'SET_READ_TASK',

  FETCH_UPDATE_TASK: 'FETCH_UPDATE_TASK',
  SET_UPDATE_TASK: 'SET_UPDATE_TASK',

  FETCH_DELETE_TASK: 'FETCH_DELETE_TASK',
  SET_DELETE_TASK: 'SET_DELETE_TASK',

  FETCH_READ_TASK_LIST: 'FETCH_READ_TASK_LIST',
  SET_READ_TASK_LIST: 'SET_READ_TASK_LIST',

  RESET_TASK: 'RESET_TASK',
  RESET_TASK_LIST: 'RESET_TASK_LIST',
}

export default {
  addTask: (competitionId, hostId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_TASK })
    return request.post({
      url: `/competition/${competitionId}/host/${hostId}/task`,
      type: types.SET_CREATE_TASK,
      dispatch,
      data,
    })
  },

  getTask: (competitionId, hostId, taskId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_TASK })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}/task/${taskId}`,
      type: types.SET_READ_TASK,
      dispatch,
    })
  },

  updateTask: (competitionId, hostId, taskId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_TASK })
    return request.patch({
      url: `/competition/${competitionId}/host/${hostId}/task/${taskId}`,
      type: types.SET_UPDATE_TASK,
      dispatch,
      data,
    })
  },

  deleteTask: (competitionId, hostId, taskId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_TASK })
    return request.delete({
      url: `/competition/${competitionId}/host/${hostId}/task/${taskId}`,
      type: types.SET_DELETE_TASK,
      dispatch,
    })
  },

  getTaskList: (competitionId, hostId, params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_TASK_LIST })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}/task`,
      type: types.SET_READ_TASK_LIST,
      dispatch,
      params,
    })
  },

  resetTask: () => (dispatch) => {
    return dispatch({ type: types.RESET_TASK })
  },

  restTaskList: () => (dispatch) => {
    return dispatch({ type: types.RESET_TASK_LIST })
  },
}
