import React from 'react'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import { Row, Icon, Modal, Button } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import actions from '@client/actions'

// import styles from './ErrorModal.styl';

const mapStateToProps = (state) => ({
  error: state.error.data,
})

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) }
}

class ErrorModal extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    message: PropTypes.func,
    width: PropTypes.string,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    message: null,
    width: '80%',
    error: {},
  }

  render() {
    const { actions: propActions, error, message, t } = this.props

    return (
      <Modal
        title={null}
        width={this.props.width}
        visible={!!error.code}
        onCancel={propActions.clearError}
        footer={
          <Button key="submit" className="primary" onClick={propActions.clearError}>
            {t('確認')}
          </Button>
        }
      >
        <Row type="flex" justify="center">
          <Icon
            type="close-circle"
            style={{ color: '#FF6B6B', fontSize: 26, padding: '20px 0 30px 0' }}
          />
        </Row>
        <Row type="flex" justify="center">
          <h2 style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
            {message ? message() : error.message}
          </h2>
        </Row>
      </Modal>
    )
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation(),
)(ErrorModal)
