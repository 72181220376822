import { types } from '@client/actions/requestCount'

const INIT_STATE = {
  data: 0,
}

const requestCount = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.INCREASE_REQUEST: {
      const count = state.data
      return {
        ...state,
        data: count + 1,
      }
    }

    case types.DECREASE_REQUEST: {
      const count = state.data
      return {
        ...state,
        data: count - 1,
      }
    }

    default:
      return state
  }
}

export default { requestCount }
