import React from 'react'
import { find } from 'lodash'
import { object, array, bool } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NotFound } from '@client/components/index'
import actions from '@client/actions'

const CheckHostExist = (Component) => {
  const mapStateToProps = (state) => ({
    navigatorHostList: state.navigatorHostList.data,
    isFetchDone: state.navigatorHostList.done,
  })
  const mapDispatchToProps = (dispatch) => {
    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class extends React.Component {
      static propTypes = {
        match: object.isRequired,
        navigatorHostList: array.isRequired,
        isFetchDone: bool.isRequired,
        actions: object.isRequired,
      }

      componentDidMount() {
        const {
          match: {
            params: { competitionId },
          },
        } = this.props
        if (competitionId) {
          this.props.actions.getNavigatorHostList(competitionId)
        }
      }

      render() {
        const { navigatorHostList, isFetchDone } = this.props
        let foundHost = {}
        if (isFetchDone) {
          foundHost = find(navigatorHostList, (host) => {
            return host.id === this.props.match.params.hostId
          })
          if (!foundHost) return <NotFound title="Host" />
        }

        return <Component {...this.props} host={foundHost} />
      }
    },
  )
}

export default CheckHostExist
