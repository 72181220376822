import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_CHALLENGE: 'FETCH_CREATE_CHALLENGE',
  SET_CREATE_CHALLENGE: 'SET_CREATE_CHALLENGE',

  FETCH_READ_CHALLENGE: 'FETCH_READ_CHALLENGE',
  SET_READ_CHALLENGE: 'SET_READ_CHALLENGE',

  FETCH_UPDATE_CHALLENGE: 'FETCH_UPDATE_CHALLENGE',
  SET_UPDATE_CHALLENGE: 'SET_UPDATE_CHALLENGE',

  FETCH_DELETE_CHALLENGE: 'FETCH_DELETE_CHALLENGE',
  SET_DELETE_CHALLENGE: 'SET_DELETE_CHALLENGE',

  FETCH_READ_CHALLENGE_LIST: 'FETCH_CHALLENGE_LIST',
  SET_READ_CHALLENGE_LIST: 'SET_READ_CHALLENGE_LIST',

  FETCH_READ_CHALLENGE_LIST_BY_INDUSTRY: 'FETCH_READ_CHALLENGE_LIST_BY_INDUSTRY',
  SET_READ_CHALLENGE_LIST_BY_INDUSTRY: 'SET_READ_CHALLENGE_LIST_BY_INDUSTRY',

  RESET_CHALLENGE: 'RESET_CHALLENGE',
  RESET_CHALLENGE_LIST: 'RESET_CHALLENGE_LIST',
}

export default {
  addChallenge: (competitionId, hostId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_CHALLENGE })
    return request.post({
      url: `/competition/${competitionId}/host/${hostId}/challenge`,
      type: types.SET_CREATE_CHALLENGE,
      dispatch,
      data,
    })
  },

  getChallenge: (competitionId, hostId, challengeId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_CHALLENGE })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}/challenge/${challengeId}`,
      type: types.SET_READ_CHALLENGE,
      dispatch,
    })
  },

  updateChallenge: (competitionId, hostId, challengeId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_CHALLENGE })
    return request.patch({
      url: `/competition/${competitionId}/host/${hostId}/challenge/${challengeId}`,
      type: types.SET_UPDATE_CHALLENGE,
      dispatch,
      data,
    })
  },

  deleteChallenge: (competitionId, hostId, challengeId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_CHALLENGE })
    return request.delete({
      url: `/competition/${competitionId}/host/${hostId}/challenge/${challengeId}`,
      type: types.SET_DELETE_CHALLENGE,
      dispatch,
    })
  },

  getChallengeList: (competitionId, hostId, params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_CHALLENGE_LIST })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}/challenge`,
      type: types.SET_READ_CHALLENGE_LIST,
      dispatch,
      params,
    })
  },

  resetChallenge: () => (dispatch) => {
    return dispatch({ type: types.RESET_CHALLENGE })
  },

  restChallengeList: () => (dispatch) => {
    return dispatch({ type: types.RESET_CHALLENGE_LIST })
  },
}
