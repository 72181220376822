import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_COMPETITION: 'FETCH_CREATE_COMPETITION',
  SET_CREATE_COMPETITION: 'SET_CREATE_COMPETITION',

  FETCH_READ_COMPETITION: 'FETCH_READ_COMPETITION',
  SET_READ_COMPETITION: 'SET_READ_COMPETITION',

  FETCH_UPDATE_COMPETITION: 'FETCH_UPDATE_COMPETITION',
  SET_UPDATE_COMPETITION: 'SET_UPDATE_COMPETITION',

  FETCH_DELETE_COMPETITION: 'FETCH_DELETE_COMPETITION',
  SET_DELETE_COMPETITION: 'SET_DELETE_COMPETITION',

  FETCH_READ_COMPETITION_LIST: 'FETCH_READ_COMPETITION_LIST',
  SET_READ_COMPETITION_LIST: 'SET_READ_COMPETITION_LIST',

  RESET_COMPETITION: 'RESET_COMPETITION',
  RESET_COMPETITION_LIST: 'RESET_COMPETITION_LIST',
}

export default {
  addCompetition: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_COMPETITION })
    return request.post({
      url: '/competition',
      type: types.SET_CREATE_COMPETITION,
      dispatch,
      data,
    })
  },

  getCompetition: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_COMPETITION })
    return request.get({
      url: `/competition/${id}`,
      type: types.SET_READ_COMPETITION,
      dispatch,
    })
  },

  updateCompetition: (id, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_COMPETITION })
    return request.patch({
      url: `/competition/${id}`,
      type: types.SET_UPDATE_COMPETITION,
      dispatch,
      data,
    })
  },

  deleteCompetition: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_COMPETITION })
    return request.delete({
      url: `/competition/${id}`,
      type: types.SET_DELETE_COMPETITION,
      dispatch,
    })
  },

  getCompetitionList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_COMPETITION_LIST })
    return request.get({
      url: '/competition',
      type: types.SET_READ_COMPETITION_LIST,
      dispatch,
      params,
    })
  },

  resetCompetition: () => (dispatch) => {
    return dispatch({ type: types.RESET_COMPETITION })
  },

  restCompetitionList: () => (dispatch) => {
    return dispatch({ type: types.RESET_COMPETITION_LIST })
  },
}
