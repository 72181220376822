import { of } from 'rxjs'
import { combineEpics, ofType } from 'redux-observable'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { fetchSampleSuccess, fetchSampleFailure } from '@client/store/sample/action'
import { FETCH_SAMPLE_REQUEST } from '@client/store/sample/type'
import { fetchSample } from '@client/apis/sample'

const fetchSampleEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_SAMPLE_REQUEST),
    mergeMap(({ payload }) =>
      fetchSample(payload).pipe(map((response) => fetchSampleSuccess(response))),
    ),
    catchError((error) => of(fetchSampleFailure({ errorMessage: error.message }))),
  )

export default combineEpics(fetchSampleEpic)
