import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_NAVIGATOR_COMPETITION: 'FETCH_CREATE_NAVIGATOR_COMPETITION',
  SET_CREATE_NAVIGATOR_COMPETITION: 'SET_CREATE_NAVIGATOR_COMPETITION',

  FETCH_READ_NAVIGATOR_COMPETITION: 'FETCH_READ_NAVIGATOR_COMPETITION',
  READ_NAVIGATOR_COMPETITION: 'READ_NAVIGATOR_COMPETITION',

  FETCH_UPDATE_NAVIGATOR_COMPETITION: 'FETCH_UPDATE_NAVIGATOR_COMPETITION',
  SET_UPDATE_NAVIGATOR_COMPETITION: 'SET_UPDATE_NAVIGATOR_COMPETITION',

  FETCH_DELETE_NAVIGATOR_COMPETITION: 'FETCH_DELETE_NAVIGATOR_COMPETITION',
  SET_DELETE_NAVIGATOR_COMPETITION: 'SET_DELETE_NAVIGATOR_COMPETITION',

  FETCH_READ_NAVIGATOR_COMPETITION_LIST: 'FETCH_READ_NAVIGATOR_COMPETITION_LIST',
  SET_READ_NAVIGATOR_COMPETITION_LIST: 'SET_READ_NAVIGATOR_COMPETITION_LIST',

  RESET_NAVIGATOR_COMPETITION: 'RESET_NAVIGATOR_COMPETITION',
  RESET_NAVIGATOR_COMPETITION_LIST: 'RESET_NAVIGATOR_COMPETITION_LIST',
}

export default {
  addNavigatorCompetition: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_NAVIGATOR_COMPETITION })
    return request.post({
      url: '/competition',
      type: types.SET_CREATE_NAVIGATOR_COMPETITION,
      dispatch,
      data,
    })
  },

  getNavigatorCompetition: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_NAVIGATOR_COMPETITION })
    return request.get({
      url: `/competition/${id}`,
      type: types.SET_READ_NAVIGATOR_COMPETITION,
      dispatch,
    })
  },

  updateNavigatorCompetition: (id, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_NAVIGATOR_COMPETITION })
    return request.patch({
      url: `/competition/${id}`,
      type: types.SET_UPDATE_NAVIGATOR_COMPETITION,
      dispatch,
      data,
    })
  },

  deleteNavigatorCompetition: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_NAVIGATOR_COMPETITION })
    return request.delete({
      dispatch,
      url: `/competition/${id}`,
      type: types.SET_DELETE_NAVIGATOR_COMPETITION,
    })
  },

  getNavigatorCompetitionList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_NAVIGATOR_COMPETITION_LIST })
    return request.get({
      url: '/competition',
      type: types.SET_READ_NAVIGATOR_COMPETITION_LIST,
      dispatch,
      params,
    })
  },

  resetNavigatorCompetition: () => (dispatch) => {
    return dispatch({ type: types.RESET_NAVIGATOR_COMPETITION })
  },

  restNavigatorCompetitionList: () => (dispatch) => {
    return dispatch({ type: types.RESET_NAVIGATOR_COMPETITION_LIST })
  },
}
