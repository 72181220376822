import { types } from '@client/actions/draft'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const draft = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_DRAFT:
    case types.FETCH_READ_DRAFT:
    case types.FETCH_UPDATE_DRAFT:
    case types.FETCH_DELETE_DRAFT: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_DRAFT:
    case types.SET_READ_DRAFT:
    case types.SET_UPDATE_DRAFT:
    case types.SET_DELETE_DRAFT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}
const draftList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_READ_DRAFT_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_DRAFT_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_DRAFT_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  draft,
  draftList,
}
