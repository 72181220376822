export const types = {
  ERROR_OCCURRED: 'ERROR_OCCURRED',
  RESET_ERROR: 'RESET_ERROR',
}

export default {
  setError: (error) => (dispatch) => {
    return dispatch({ type: types.ERROR_OCCURRED, payload: error })
  },

  clearError: () => (dispatch) => {
    return dispatch({ type: types.RESET_ERROR })
  },
}
