import request from '@client/lib/ApiClient'

export const types = {
  FETCH_ADMIN_CREATE_USER: 'FETCH_ADMIN_CREATE_USER',
  SET_ADMIN_CREATE_USER: 'SET_ADMIN_CREATE_USER',

  FETCH_ADMIN_DELETE_USER: 'FETCH_ADMIN_DELETE_USER',
  SET_ADMIN_DELETE_USER: 'SET_ADMIN_DELETE_USER',

  FETCH_ADMIN_READ_USER_LIST: 'FETCH_ADMIN_READ_USER_LIST',
  SET_ADMIN_READ_USER_LIST: 'SET_ADMIN_READ_USER_LIST',
}

export default {
  adminAddUser: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_ADMIN_CREATE_USER })
    return request.post({ url: '/admin/user', type: types.SET_ADMIN_CREATE_USER, dispatch, data })
  },

  adminDeleteUser: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_ADMIN_DELETE_USER })
    return request.delete({
      url: `/admin/user/${id}`,
      type: types.SET_ADMIN_DELETE_USER,
      dispatch,
    })
  },

  adminGetUserList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_ADMIN_READ_USER_LIST })
    return request.get({
      url: '/admin/user',
      type: types.SET_ADMIN_READ_USER_LIST,
      dispatch,
      params,
    })
  },

  adminResetUser: () => (dispatch) => {
    return dispatch({ type: types.ADMIN_RESET_USER })
  },

  adminRestUserList: () => (dispatch) => {
    return dispatch({ type: types.ADMIN_RESET_USER_LIST })
  },
}
