import _ from 'lodash'
import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Row, Col, Icon, Menu, Button } from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { matchPath } from 'react-router'
import actions from '@client/actions'
import { I18N_LANG_LIST } from '@client/i18n/index'
import iconImage from '@client/images/icon.png'
import { Image } from '@client/components/index'

import ROLE from '@server/constants/roles'
import styles from './Navigation.styl'

const { SubMenu } = Menu

const mapStateToProps = (state) => ({
  auth: state.auth.data,
  isLogin: state.auth.isLogin,
  host: state.host.data,
  navigatorCompetitionList: state.navigatorCompetitionList.data,
})

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) }
}

class Navigation extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    navigatorCompetitionList: PropTypes.array.isRequired,
    isLogin: PropTypes.bool.isRequired,
    i18n: PropTypes.object.isRequired,
    host: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      collapsed: true,
    }
  }

  componentDidMount() {
    const match = matchPath(window.location.pathname, {
      path: '/competition/:competitionId/host/:hostId',
    })

    if (match) {
      const { competitionId, hostId } = match.params
      if (competitionId && hostId) {
        this.props.actions.getHost(competitionId, hostId)
      }
    }
  }

  render() {
    return (
      <div className={styles.navigator}>
        <div className={styles.smallMenu}>
          <Button className={styles.menuButton} onClick={this.toggleCollapsed}>
            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
          </Button>
          {this.renderMenu('inline')}
        </div>

        <Row className={styles.largeMenu} type="flex" justify="center" align="bottom">
          <div className={styles.navigator__background} />
          <Col span={20}>{this.renderMenu('horizontal')}</Col>
        </Row>
      </div>
    )
  }

  getLangName = (lang) => {
    const currLang = _.find(I18N_LANG_LIST, { lang })
    return currLang ? currLang.name : ''
  }

  renderMenu = (mode, theme) => {
    const props = { mode }
    const { auth, navigatorCompetitionList, location, t, isLogin, host } = this.props
    if (mode === 'inline') props.inlineCollapsed = this.state.collapsed
    const isIndependentPage = host.id && host.is_independent_page
    return (
      <Menu {...props} selectedKeys={[location.pathname]}>
        <Menu.Item key="/">
          {isIndependentPage && host.landing_page_url ? (
            <a href={host.landing_page_url}>
              <Image className={styles.navigatorIcon} src={iconImage} />
              <span>{host.landing_page_menu_name}</span>
            </a>
          ) : (
            <Link to="/">
              <Image className={styles.navigatorIcon} src={iconImage} />
              <span>{t('首頁')}</span>
            </Link>
          )}
        </Menu.Item>
        {/* <Menu.Item key="/more_story">
          <Link to="/more_story">
            <Image className={styles.navigatorIcon} src={iconImage} />
            <span>{t('浪人故事')}</span>
          </Link>
        </Menu.Item> */}
        {!isIndependentPage && (
          <Menu.Item key="/about_us">
            <Link to="/about_us">
              <Image className={styles.navigatorIcon} src={iconImage} />
              <span>{t('關於我們')}</span>
            </Link>
          </Menu.Item>
        )}
        {!isIndependentPage && (
          <SubMenu
            title={
              <span>
                <Image className={styles.navigatorIcon} src={iconImage} />
                {t('挑戰賽')}
              </span>
            }
          >
            {navigatorCompetitionList
              .filter((competition) => competition.is_visible)
              .map((competition) => {
                return (
                  <Menu.Item key={`/competition/${competition.id}`}>
                    <Link to={`/competition/${competition.id}`}>
                      {competition.name}
                      {competition.is_hot && (
                        <Icon theme="filled" className={styles.hotIcon} type="fire" />
                      )}
                    </Link>
                  </Menu.Item>
                )
              })}
          </SubMenu>
        )}

        {[ROLE.MANAGER, ROLE.VOLUNTEER, ROLE.USER].indexOf(auth.role) > -1 && (
          <Menu.Item key={`/competition/${auth.competition_id}/host/${auth.host_id}`}>
            <Link to={`/competition/${auth.competition_id}/host/${auth.host_id}`}>
              <Image className={styles.navigatorIcon} src={iconImage} />
              <span>{t('我的賽區')}</span>
            </Link>
          </Menu.Item>
        )}

        {this.renderChangeLangMenu()}

        {isLogin ? (
          this.renderSettingMenu()
        ) : (
          <Menu.Item key="/login">
            <Link to="/login">
              <Icon type="login" />
              {t('登入')}
            </Link>
          </Menu.Item>
        )}
      </Menu>
    )
  }

  renderChangeLangMenu = () => {
    const {
      i18n: { language },
    } = this.props
    return (
      <SubMenu title={<span>{this.getLangName(language)}</span>}>
        {I18N_LANG_LIST.map(({ lang, name }) => {
          return (
            <Menu.Item key={lang} onClick={() => this.handleClickLang(lang)}>
              {name}
            </Menu.Item>
          )
        })}
      </SubMenu>
    )
  }

  handleClickLang = (lang) => {
    const { i18n } = this.props
    i18n.changeLanguage(lang)
    document.documentElement.setAttribute('lang', lang)
  }

  renderSettingMenu = () => {
    const {
      auth,
      t,
      actions: { logout },
    } = this.props
    return (
      <SubMenu
        title={
          <div className={styles.navigatorPerson}>
            <Image src={`${auth.cover}`} />
            <span>{auth.email}</span>
          </div>
        }
      >
        {_.includes([ROLE.MANAGER, ROLE.ADMIN], auth.role) && (
          <Menu.Item key="/competition_management">
            <Link to="/competition_management">
              <Icon type="flag" />
              {t('屆數管理')}
            </Link>
          </Menu.Item>
        )}
        {_.includes([ROLE.MANAGER, ROLE.ADMIN], auth.role) && (
          <Menu.Item key="/host_management">
            <Link to="/host_management">
              <Icon type="flag" />
              {t('區域管理')}
            </Link>
          </Menu.Item>
        )}
        {_.includes([ROLE.MANAGER, ROLE.ADMIN], auth.role) && (
          <Menu.Item key="/user_management">
            <Link to="/user_management">
              <Icon type="user-add" />
              {t('使用者管理')}
            </Link>
          </Menu.Item>
        )}
        {_.includes([ROLE.MANAGER, ROLE.ADMIN], auth.role) && (
          <Menu.Item key="/form_format_management">
            <Link to="/form_format_management">
              <Icon type="profile" />
              {t('報名表單管理')}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="/account_setting">
          <Link to="/account_setting">
            <Icon type="solution" />
            {t('帳號設定')}
          </Link>
        </Menu.Item>
        <Menu.Item key="logout" onClick={logout}>
          <Icon type="logout" />
          {t('登出')}
        </Menu.Item>
      </SubMenu>
    )
  }

  toggleCollapsed = () => {
    this.setState((state) => ({
      collapsed: !state.collapsed,
    }))
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Navigation)
