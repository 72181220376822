import { types } from '@client/actions/formFormat'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const formFormat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_FORM_FORMAT:
    case types.FETCH_READ_FORM_FORMAT:
    case types.FETCH_UPDATE_FORM_FORMAT:
    case types.FETCH_DELETE_FORM_FORMAT: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_FORM_FORMAT:
    case types.SET_READ_FORM_FORMAT:
    case types.SET_UPDATE_FORM_FORMAT:
    case types.SET_DELETE_FORM_FORMAT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}
const formFormatList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_READ_FORM_FORMAT_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_FORM_FORMAT_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_FORM_FORMAT_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  formFormat,
  formFormatList,
}
