import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Menu, Icon, Col } from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import ROLE from '@server/constants/roles'

import styles from './CompetitionNavigation.styl'

const { SubMenu } = Menu

export const NAV_URLS = {
  COMPETITION: {
    url: '',
    name: '屆數首頁',
  },
  HOST: {
    url: '',
    name: '區域首頁',
  },
  SIGN_UP: {
    url: '/sign_up',
    name: '報名頁面',
  },
  COMPETITION_INFO: {
    url: '/competition_info',
    name: '活動說明',
  },
  UPLOAD_INFO: {
    url: '/upload_info',
    name: '上傳任務說明',
  },
  RULE_INFO: {
    url: '/signup_info',
    name: '報名規則說明',
  },
  HOST_SETTING: {
    url: '/host_setting',
    name: '區域設定',
  },
  TASK_MANAGEMENT: {
    url: '/task_management',
    name: '任務管理',
  },
  CHALLENGE_REVIEW: {
    url: '/challenge_review',
    name: '挑戰審核',
  },
  TEAM_MANAGEMENT: {
    url: '/team_management',
    name: '團隊管理',
  },
  TASK_LIST: {
    url: '/task_list',
    name: '挑戰任務列表',
  },
  TEAM_SETTING: {
    url: '/team_setting',
    name: '團隊設定',
  },
  TEAM: {
    url: '/team',
    name: '我的團隊',
  },
}

const mapStateToProps = (state) => ({
  auth: state.auth.data,
  host: state.host.data,
})

const mapDispatchToProps = (dispatch) => {
  return {}
}

class CompetitionNavigation extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    host: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  getUrl = (url) => {
    const {
      params: { competitionId, hostId },
    } = this.props.match
    return `/competition/${competitionId}/host/${hostId}${url}`
  }

  checkPermission = () => {
    const {
      auth,
      match: {
        params: { competitionId, hostId },
      },
    } = this.props
    if (
      auth.role !== ROLE.ADMIN &&
      (auth.competition_id !== competitionId || auth.host_id !== hostId)
    ) {
      return false
    }
    return true
  }

  render() {
    const { auth, match, t, host } = this.props
    const {
      params: { competitionId, hostId },
    } = match

    let selectKey = match.url
    if (new RegExp(`${NAV_URLS.TASK_LIST.url}/?[^/]+`).test(match.url)) {
      selectKey = _.dropRight(selectKey.split('/')).join('/')
    }

    // TODO: need to fix ssr layout issue
    return (
      <Col span={20}>
        <Menu selectedKeys={[selectKey]} mode="horizontal" className={styles.navigation}>
          {!host.is_independent_page &&
            [NAV_URLS.COMPETITION].map((menu) => {
              const fullUrl = `/competition/${competitionId}${menu.url}`
              return (
                <Menu.Item key={fullUrl}>
                  <Link to={fullUrl}>{t(menu.name)}</Link>
                </Menu.Item>
              )
            })}
          {hostId &&
            [NAV_URLS.HOST].map((menu) => {
              const fullUrl = `/competition/${competitionId}/host/${hostId}`
              return (
                <Menu.Item key={fullUrl}>
                  <Link to={fullUrl}>
                    {host.is_independent_page ? t('賽務首頁') : t(menu.name)}
                  </Link>
                </Menu.Item>
              )
            })}
          {hostId &&
            this.checkPermission() &&
            _.includes([ROLE.USER], auth.role) &&
            [NAV_URLS.TEAM].map((menu) => {
              const fullUrl = `/competition/${competitionId}/host/${hostId}/team/${auth.team_id}`
              return (
                <Menu.Item key={fullUrl}>
                  <Link to={fullUrl}>{t(menu.name)}</Link>
                </Menu.Item>
              )
            })}
          {hostId &&
            [NAV_URLS.SIGN_UP].map((menu) => {
              const fullUrl = this.getUrl(menu.url)
              return (
                <Menu.Item key={fullUrl}>
                  <Link to={fullUrl}>{t(menu.name)}</Link>
                </Menu.Item>
              )
            })}
          {hostId &&
            !host.is_independent_page &&
            [NAV_URLS.COMPETITION_INFO].map((menu) => {
              const fullUrl = this.getUrl(menu.url)
              return (
                <Menu.Item key={fullUrl}>
                  <Link to={fullUrl}>{t(menu.name)}</Link>
                </Menu.Item>
              )
            })}
          {hostId &&
            this.checkPermission() &&
            _.includes([ROLE.ADMIN, ROLE.MANAGER, ROLE.USER], auth.role) &&
            [NAV_URLS.TASK_LIST].map((menu) => {
              const fullUrl = this.getUrl(menu.url)
              return (
                <Menu.Item key={fullUrl}>
                  <Link to={fullUrl}>{t(menu.name)}</Link>
                </Menu.Item>
              )
            })}
          {hostId && this.checkPermission() && _.includes(_.values(ROLE), auth.role) && (
            <SubMenu
              title={
                <span>
                  <Icon type="setting" />
                  {t('設定')}
                </span>
              }
            >
              {_.includes([ROLE.ADMIN, ROLE.MANAGER], auth.role) &&
                [
                  NAV_URLS.HOST_SETTING,
                  NAV_URLS.TASK_MANAGEMENT,
                  NAV_URLS.CHALLENGE_REVIEW,
                  NAV_URLS.TEAM_MANAGEMENT,
                ].map((menu) => {
                  const fullUrl = this.getUrl(menu.url)
                  return (
                    <Menu.Item key={fullUrl}>
                      <Link to={fullUrl}>{t(menu.name)}</Link>
                    </Menu.Item>
                  )
                })}
              {_.includes([ROLE.VOLUNTEER], auth.role) &&
                [NAV_URLS.CHALLENGE_REVIEW].map((menu) => {
                  const fullUrl = this.getUrl(menu.url)
                  return (
                    <Menu.Item key={fullUrl}>
                      <Link to={fullUrl}>{t(menu.name)}</Link>
                    </Menu.Item>
                  )
                })}
              {_.includes([ROLE.USER], auth.role) && (
                <Menu.Item
                  key={`/competition/${competitionId}/host/${hostId}/team/${auth.team_id}${NAV_URLS.TEAM_SETTING.url}`}
                >
                  <Link
                    to={`/competition/${competitionId}/host/${hostId}/team/${auth.team_id}${NAV_URLS.TEAM_SETTING.url}`}
                  >
                    {t(NAV_URLS.TEAM_SETTING.name)}
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
        </Menu>
      </Col>
    )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(CompetitionNavigation)
