import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import defaultImage from '@client/images/default-avatar.png'

const Image = ({ src, defaultSrc, lazyLoad, ...props }) =>
  lazyLoad ? (
    <LazyLoad height="100%" once>
      <img
        src={src || defaultSrc || defaultImage}
        onError={(e) => {
          const { target } = e
          target.src = defaultSrc
        }}
        {...props}
      />
    </LazyLoad>
  ) : (
    <img
      src={src || defaultSrc || defaultImage}
      onError={(e) => {
        const { target } = e
        target.src = defaultSrc
      }}
      {...props}
    />
  )

Image.propTypes = {
  lazyLoad: PropTypes.bool,
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
}

Image.defaultProps = {
  lazyLoad: true,
  src: '',
  defaultSrc: defaultImage,
}

export default Image
