import { types } from '@client/actions/challenge'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const challenge = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_CHALLENGE:
    case types.FETCH_READ_CHALLENGE:
    case types.FETCH_UPDATE_CHALLENGE:
    case types.FETCH_DELETE_CHALLENGE: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_CHALLENGE:
    case types.SET_READ_CHALLENGE:
    case types.SET_UPDATE_CHALLENGE:
    case types.SET_DELETE_CHALLENGE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}
const challengeList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_READ_CHALLENGE_LIST:
    case types.FETCH_READ_CHALLENGE_LIST_BY_INDUSTRY: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_CHALLENGE_LIST:
    case types.SET_READ_CHALLENGE_LIST_BY_INDUSTRY: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_CHALLENGE_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  challenge,
  challengeList,
}
