import { types } from '@client/actions/host'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const host = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_HOST:
    case types.FETCH_READ_HOST:
    case types.FETCH_UPDATE_HOST:
    case types.FETCH_DELETE_HOST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_HOST:
    case types.SET_READ_HOST:
    case types.SET_UPDATE_HOST:
    case types.SET_DELETE_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const hostList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_READ_HOST_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_HOST_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_HOST_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

const MAILER_TEST_INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
}

const hostMailerTest = (state = MAILER_TEST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_TEST_HOST_MAILER: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_TEST_HOST_MAILER: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default {
  host,
  hostList,
  hostMailerTest,
}
