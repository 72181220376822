import React from 'react'
import { object, func, string } from 'prop-types'
import { Row, Modal, Icon, Button } from 'antd'

import Utils from '@client/lib/Utils'
import { LoadingMask } from '@client/components/index'
import quillStyles from '@client/styles/quill.styl'

let ReactQuill = null

if (process.env.CLIENT) {
  ReactQuill = require('react-quill')
  const { Quill } = ReactQuill
  const ImageResize = require('@client/lib/quill-image-resize-module').default
  const VideoResize = require('@client/lib/quill-video-resize-module').default
  const ImageFormat = require('./Formats/ImageFormat').default
  const VideoFormat = require('./Formats/VideoFormat').default

  Quill.register(ImageFormat, true)
  Quill.register(VideoFormat, true)
  Quill.register('modules/ImageResize', ImageResize)
  Quill.register('modules/VideoResize', VideoResize)
}

class QuillEditor extends React.Component {
  static propTypes = {
    actions: object.isRequired,
    onChange: func.isRequired,
    onFocus: func,
    // eslint-disable-next-line
    content: string,
  }

  static defaultProps = {
    onFocus: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      isLoading: false,
      progress: 0,
    }
  }

  setProgress = (progress) => {
    if (progress === 100 || progress - this.state.progress > 10) {
      this.setState({ progress })
    }
  }

  selectImages = () => {
    return new Promise((resolve, reject) => {
      this.imageInputRef.click()
      this.imageInputRef.onchange = (e) => {
        this.setState({ isLoading: true, progress: 0 })
        const imageList = []
        Array.from(e.target.files).forEach((file) => {
          imageList.push(file)
        })
        resolve(imageList)
      }
    })
  }

  insertToEditor = (url) => {
    const editor = this.quillRef.getEditor()
    const range = editor.getSelection() || { index: 0 }
    editor.insertEmbed(range.index, 'image', url)
  }

  modules = {
    ImageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    VideoResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    toolbar: {
      formula: true,
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
        ['blockquote', 'code-block'],
        [{ header: [1, 2, 3, 4, 5, false] }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],

      handlers: {
        image: (img, callback) => {
          this.selectImages()
            .then((images) => {
              const fileList = images.map((image) => {
                return { name: image.name, category: 'quill' }
              })
              return this.props.actions.getSignedUrl(fileList).then((res) => {
                const signedUrlList = res.payload.data
                const uploadInfoList = signedUrlList.map((signedUrl, index) => {
                  return {
                    ...signedUrl,
                    file: images[index],
                  }
                })
                return Promise.resolve(uploadInfoList)
              })
            })
            .then((uploadInfoList) => {
              const promiseProgress = []
              return uploadInfoList.reduce((prev, uploadInfo, index) => {
                return prev.then(() => {
                  return Utils.resizeImage(uploadInfo.file)
                    .then((resizedFile) => {
                      return Utils.uploadToS3(
                        {
                          ...uploadInfo,
                          file: resizedFile,
                        },
                        (progress) => {
                          promiseProgress[index] = progress
                          const totalProgress = promiseProgress.reduce((pre, curr) => {
                            return pre + curr
                          }, 0)
                          const averageProgress = totalProgress / uploadInfoList.length
                          this.setProgress(averageProgress)
                          return prev + averageProgress
                        },
                      )
                    })
                    .then((url) => {
                      this.insertToEditor(url)
                    })
                })
              }, Promise.resolve())
            })
            .finally(() => {
              this.imageInputRef.value = ''
              this.setState({ isLoading: false })
            })
        },
      },
    },
  }

  hideModal = () => {
    this.setState({ errorMessage: '' })
  }

  render() {
    const { content, onFocus, onChange } = this.props
    if (!process.env.CLIENT) return <textarea value={this.props.content} />
    return (
      <>
        <ReactQuill
          className={quillStyles.article}
          ref={(el) => {
            this.quillRef = el
          }}
          style={{ margin: '0px' }}
          modules={this.modules}
          onFocus={onFocus}
          onChange={onChange}
          theme="snow"
          value={content || ''}
        />

        <input
          accept="image/*"
          ref={(el) => {
            this.imageInputRef = el
          }}
          type="file"
          style={{ display: 'none' }}
          multiple
        />

        <Modal
          title={null}
          visible={!!this.state.errorMessage}
          onCancel={this.hideModal}
          footer={
            <Button key="submit" className="primary" onClick={this.hideModal}>
              確認
            </Button>
          }
        >
          <Row type="flex" justify="center">
            <Icon
              type="close-circle"
              style={{ color: '#FF6B6B', fontSize: 26, padding: '20px 0 30px 0' }}
            />
          </Row>
          <Row type="flex" justify="center">
            <h2>{this.state.errorMessage}</h2>
          </Row>
        </Modal>

        <LoadingMask showProgress visible={this.state.isLoading} progress={this.state.progress} />
      </>
    )
  }
}

export default QuillEditor
