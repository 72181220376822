import request from '@client/lib/ApiClient'

export const types = {
  FETCH_ADMIN_SEND_MAIL: 'FETCH_ADMIN_SEND_MAIL',
  SET_ADMIN_SEND_MAIL: 'SET_ADMIN_SEND_MAIL',
}

export default {
  adminSendTeamMail: (teamId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_ADMIN_SEND_MAIL })
    return request.post({
      dispatch,
      type: types.SET_ADMIN_SEND_MAIL,
      url: `/admin/mail/team/${teamId}`,
      data,
    })
  },
}
