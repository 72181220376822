import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_RESET_PASSWORD: 'FETCH_CREATE_RESET_PASSWORD',
  SET_CREATE_RESET_PASSWORD: 'SET_CREATE_RESET_PASSWORD',

  FETCH_READ_RESET_PASSWORD: 'FETCH_READ_RESET_PASSWORD',
  SET_READ_RESET_PASSWORD: 'SET_READ_RESET_PASSWORD',

  FETCH_UPDATE_RESET_PASSWORD: 'FETCH_UPDATE_RESET_PASSWORD',
  SET_UPDATE_RESET_PASSWORD: 'SET_UPDATE_RESET_PASSWORD',

  FETCH_DELETE_RESET_PASSWORD: 'FETCH_DELETE_RESET_PASSWORD',
  SET_DELETE_RESET_PASSWORD: 'SET_DELETE_RESET_PASSWORD',

  FETCH_READ_RESET_PASSWORD_LIST: 'FETCH_READ_RESET_PASSWORD_LIST',
  SET_READ_RESET_PASSWORD_LIST: 'SET_READ_RESET_PASSWORD_LIST',

  RESET_RESET_PASSWORD: 'RESET_RESET_PASSWORD',
  RESET_RESET_PASSWORD_LIST: 'RESET_RESET_PASSWORD_LIST',
}

export default {
  addResetPassword: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_RESET_PASSWORD })
    return request.post({
      url: '/resetPassword',
      type: types.SET_CREATE_RESET_PASSWORD,
      dispatch,
      data,
    })
  },

  getResetPassword: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_RESET_PASSWORD })
    return request.get({
      url: `/resetPassword/${id}`,
      type: types.SET_READ_RESET_PASSWORD,
      dispatch,
    })
  },

  updateResetPassword: (id, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_RESET_PASSWORD })
    return request.patch({
      url: `/resetPassword/${id}`,
      type: types.SET_UPDATE_RESET_PASSWORD,
      dispatch,
      data,
    })
  },

  deleteResetPassword: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_RESET_PASSWORD })
    return request.delete({
      url: `/resetPassword/${id}`,
      type: types.SET_DELETE_RESET_PASSWORD,
      dispatch,
    })
  },

  getResetPasswordList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_RESET_PASSWORD_LIST })
    return request.get({
      url: '/resetPassword',
      type: types.SET_READ_RESET_PASSWORD_LIST,
      dispatch,
      params,
    })
  },

  resetResetPassword: () => (dispatch) => {
    return dispatch({ type: types.RESET_RESET_PASSWORD })
  },

  restResetPasswordList: () => (dispatch) => {
    return dispatch({ type: types.RESET_RESET_PASSWORD_LIST })
  },
}
