import React from 'react'
import { number, node, bool, object } from 'prop-types'
import { Transition } from 'react-transition-group'
import _ from 'lodash'

class FadeInTop extends React.Component {
  static propTypes = {
    duration: number,
    delay: number,
    children: node,
    show: bool,
    style: object,
  }

  static defaultProps = {
    duration: 5000,
    delay: 0,
    children: null,
    style: {},
    show: false,
  }

  render() {
    const { children, duration, delay, show, style } = this.props
    const childProps = _.omit(this.props, ['duration', 'delay', 'show', 'style'])
    const defaultStyle = {
      opacity: 0,
    }
    const transitionStyles = {
      entering: { opacity: 0, transform: 'translateY(-20px)' },
      entered: {
        opacity: 1,
        transform: 'translateY(0px)',
        transition: `all ${duration}ms ease-out ${delay}ms`,
      },
    }

    return (
      <Transition in={show} timeout={duration}>
        {(state) => (
          <div
            {...childProps}
            style={{
              ...defaultStyle,
              ...style,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    )
  }
}

export default FadeInTop
