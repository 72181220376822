import request from '@client/lib/ApiClient'

export const types = {
  FETCH_READ_TIME: 'FETCH_READ_TIME',
  SET_READ_TIME: 'SET_READ_TIME',
}

export default {
  getTime: () => (dispatch) => {
    dispatch({ type: types.FETCH_READ_TIME })
    return request.get({ url: '/time', type: types.SET_READ_TIME, dispatch })
  },
}
