import React, { useState } from 'react'
import { string, node, bool } from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { FadeInTop } from '@client/components/index'
import styles from './Banner.styl'
import logoImage from './images/logo.png'

const defaultImage = 'https://citywanderer.s3-ap-northeast-1.amazonaws.com/banner.png'

const Banner = ({ backgroundImage, logoFlex, title, subTitle, disabledLogo }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div
      className={styles.banner}
      style={{
        justifyContent: logoFlex,
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.99) 100%), url(${backgroundImage ||
          defaultImage})`,
      }}
    >
      <Waypoint onEnter={() => setVisible(true)} />

      <FadeInTop show={visible} duration={500}>
        <div className={styles.wrapper} style={{ width: logoFlex === 'center' ? '100vw' : '50vw' }}>
          {!disabledLogo && <img className={styles.logo} src={logoImage} />}
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{subTitle}</div>
        </div>
      </FadeInTop>
    </div>
  )
}

Banner.propTypes = {
  backgroundImage: string,
  logoFlex: string,
  title: node,
  subTitle: node,
  disabledLogo: bool,
}

Banner.defaultProps = {
  logoFlex: 'center',
  title: null,
  subTitle: null,
  disabledLogo: false,
  backgroundImage: defaultImage,
}

export default Banner
