import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_TEAM: 'FETCH_CREATE_TEAM',
  SET_CREATE_TEAM: 'SET_CREATE_TEAM',

  FETCH_READ_TEAM: 'FETCH_READ_TEAM',
  SET_READ_TEAM: 'SET_READ_TEAM',

  FETCH_UPDATE_TEAM: 'FETCH_UPDATE_TEAM',
  SET_UPDATE_TEAM: 'SET_UPDATE_TEAM',

  FETCH_DELETE_TEAM: 'FETCH_DELETE_TEAM',
  SET_DELETE_TEAM: 'SET_DELETE_TEAM',

  FETCH_READ_TEAM_LIST: 'FETCH_READ_TEAM_LIST',
  SET_READ_TEAM_LIST: 'SET_READ_TEAM_LIST',

  RESET_TEAM: 'RESET_TEAM',
  RESET_TEAM_LIST: 'RESET_TEAM_LIST',
}

export default {
  addTeam: (competitionId, hostId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_TEAM })
    return request.post({
      url: `/competition/${competitionId}/host/${hostId}/team`,
      type: types.SET_CREATE_TEAM,
      dispatch,
      data,
    })
  },

  getTeam: (competitionId, hostId, teamId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_TEAM })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}/team/${teamId}`,
      type: types.SET_READ_TEAM,
      dispatch,
    })
  },

  updateTeam: (competitionId, hostId, teamId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_TEAM })
    return request.patch({
      url: `/competition/${competitionId}/host/${hostId}/team/${teamId}`,
      type: types.SET_UPDATE_TEAM,
      dispatch,
      data,
    })
  },

  deleteTeam: (competitionId, hostId, teamId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_TEAM })
    return request.delete({
      url: `/competition/${competitionId}/host/${hostId}/team/${teamId}`,
      type: types.SET_DELETE_TEAM,
      dispatch,
    })
  },

  getTeamList: (competitionId, hostId, params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_TEAM_LIST })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}/team`,
      type: types.SET_READ_TEAM_LIST,
      dispatch,
      params,
    })
  },

  resetTeam: () => (dispatch) => {
    return dispatch({ type: types.RESET_TEAM })
  },

  restTeamList: () => (dispatch) => {
    return dispatch({ type: types.RESET_TEAM_LIST })
  },
}
