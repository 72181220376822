import {
  LoadableHome,
  LoadableMoreStory,
  LoadableAboutUs,
  LoadableLogin,
  LoadableStory,
  LoadableAccountSetting,
  LoadableCompetitionManagement,
  LoadableHostManagement,
  LoadableUserManagement,
  LoadableWebSetting,
  LoadableCompetition,
  LoadableSignUp,
  LoadableTaskManagement,
  LoadableTeam,
  LoadableHostSetting,
  LoadableChallenge,
  LoadableCompetitionInfo,
  LoadableUploadInfo,
  LoadableHost,
  LoadableTeamManagement,
  LoadableSignupInfo,
  LoadableTaskList,
  LoadableTeamSetting,
  LoadableTask,
  LoadableResetPassword,
  LoadableChallengeReview,
  LoadableFormFormatManagement,
} from '@client/scenes/index'
import ROLE from '@server/constants/roles'

const routes = [
  {
    exact: true,
    path: '/',
    component: LoadableHome,
  },
  {
    exact: true,
    path: '/more_story',
    component: LoadableMoreStory,
  },
  {
    exact: true,
    path: '/about_us',
    component: LoadableAboutUs,
  },
  {
    exact: true,
    path: '/login',
    component: LoadableLogin,
  },
  {
    exact: true,
    path: '/more_story/:storyId',
    component: LoadableStory,
  },
  {
    exact: true,
    path: '/account_setting',
    component: LoadableAccountSetting,
    validRoles: Object.values(ROLE),
  },
  {
    exact: true,
    path: '/competition_management',
    component: LoadableCompetitionManagement,
    validRoles: [ROLE.ADMIN],
  },
  {
    exact: true,
    path: '/host_management',
    component: LoadableHostManagement,
    validRoles: [ROLE.ADMIN],
  },
  {
    exact: true,
    path: '/user_management',
    component: LoadableUserManagement,
    validRoles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    exact: true,
    path: '/web_setting',
    component: LoadableWebSetting,
    validRoles: [ROLE.ADMIN],
  },
  {
    exact: true,
    path: '/form_format_management',
    component: LoadableFormFormatManagement,
    validRoles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    exact: true,
    path: '/competition/:competitionId',
    component: LoadableCompetition,
  },
  {
    exact: true,
    path: '/competition/:competitionId/upload_info',
    component: LoadableUploadInfo,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/competition_info',
    component: LoadableCompetitionInfo,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/sign_up',
    component: LoadableSignUp,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/task_management',
    component: LoadableTaskManagement,
    validRoles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/team/:teamId',
    component: LoadableTeam,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/host_setting',
    component: LoadableHostSetting,
    validRoles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/challenge/:challengeId',
    component: LoadableChallenge,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId',
    component: LoadableHost,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/team_management',
    component: LoadableTeamManagement,
    validRoles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/signup_info',
    component: LoadableSignupInfo,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/task_list/:category?',
    component: LoadableTaskList,
    validRoles: [ROLE.USER, ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/team/:teamId/team_setting',
    component: LoadableTeamSetting,
    validRoles: [ROLE.USER],
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/task/:taskId',
    component: LoadableTask,
  },
  {
    exact: true,
    path: '/resetPassword/:resetPasswordId',
    component: LoadableResetPassword,
  },
  {
    exact: true,
    path: '/competition/:competitionId/host/:hostId/challenge_review',
    component: LoadableChallengeReview,
    validRoles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.VOLUNTEER],
  },
]

export default routes
