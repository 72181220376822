import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import quillStyles from '@client/styles/quill.styl'

const QuillContent = ({ content, className }) => (
  <div className={classnames('ql-editor', quillStyles.article, className)}>
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </div>
)

QuillContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

QuillContent.defaultProps = {
  content: '',
  className: '',
}

export default QuillContent
