import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_STORY: 'FETCH_CREATE_STORY',
  SET_CREATE_STORY: 'SET_CREATE_STORY',

  FETCH_READ_STORY: 'FETCH_READ_STORY',
  SET_READ_STORY: 'SET_READ_STORY',

  FETCH_UPDATE_STORY: 'FETCH_UPDATE_STORY',
  SET_UPDATE_STORY: 'SET_UPDATE_STORY',

  FETCH_DELETE_STORY: 'FETCH_DELETE_STORY',
  SET_DELETE_STORY: 'SET_DELETE_STORY',

  FETCH_READ_STORY_LIST: 'FETCH_READ_STORY_LIST',
  SET_READ_STORY_LIST: 'SET_READ_STORY_LIST',

  RESET_STORY: 'RESET_STORY',
  RESET_STORY_LIST: 'RESET_STORY_LIST',
}

export default {
  addStory: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_STORY })
    return request.post({ url: '/story', type: types.SET_CREATE_STORY, dispatch, data })
  },

  getStory: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_STORY })
    return request.get({ url: `/story/${id}`, type: types.SET_READ_STORY, dispatch })
  },

  updateStory: (id, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_STORY })
    return request.patch({ url: `/story/${id}`, type: types.SET_UPDATE_STORY, dispatch, data })
  },

  deleteStory: (id) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_STORY })
    return request.delete({ url: `/story/${id}`, type: types.SET_DELETE_STORY, dispatch })
  },

  getStoryList: (params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_STORY_LIST })
    return request.get({ url: '/story', type: types.SET_READ_STORY_LIST, dispatch, params })
  },

  resetStory: () => (dispatch) => {
    return dispatch({ type: types.RESET_STORY })
  },

  restStoryList: () => (dispatch) => {
    return dispatch({ type: types.RESET_STORY_LIST })
  },
}
