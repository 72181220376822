import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Image } from '@client/components/index'
import styles from './AspectRatioImage.styl'

const AspectRatioImage = ({ src, defaultSrc, ratio, className, lazyLoad, ...props }) => (
  <div
    className={classnames(styles.imageWrapper, className)}
    style={{ paddingTop: `${(1 / ratio) * 100}%` }}
    {...props}
  >
    <Image src={src} defaultSrc={defaultSrc} lazyLoad={lazyLoad} />
  </div>
)

AspectRatioImage.propTypes = {
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  className: PropTypes.string,
  ratio: PropTypes.number,
  lazyLoad: PropTypes.bool,
}

AspectRatioImage.defaultProps = {
  src: '',
  defaultSrc: '',
  className: '',
  ratio: 1, // width / height
  lazyLoad: true,
}

export default AspectRatioImage
