import React from 'react'
import { bool, func } from 'prop-types'

function LoadableLoading({ error, timedOut, retry, pastDelay }) {
  if (error) {
    // When the loader has errored
    return (
      <div>
        Error!{' '}
        <button type="button" onClick={retry}>
          Retry
        </button>
      </div>
    )
  }

  if (timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time...{' '}
        <button type="button" onClick={retry}>
          Retry
        </button>
      </div>
    )
  }

  return null
}

LoadableLoading.propTypes = {
  error: bool,
  retry: func.isRequired,
  pastDelay: bool,
  timedOut: bool.isRequired,
}

LoadableLoading.defaultProps = {
  pastDelay: false,
  error: false,
}

export default LoadableLoading
