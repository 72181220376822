export default class Utils {
  static uploadToS3(data, processCallback) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', data.signedUrl, true)
      xhr.setRequestHeader('Content-type', data.file.type)
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(data.url)
        }
      }
      xhr.addEventListener('error', (event) => {
        reject(new Error(xhr.responseText))
      })
      if (processCallback) {
        xhr.upload.addEventListener(
          'progress',
          (e) => {
            if (e.lengthComputable) {
              const progress = (e.loaded / e.total) * 100
              processCallback(progress)
            }
          },
          false,
        )
      }
      xhr.send(data.file)
    })
  }

  static resizeImage(file) {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')
      const reader = new FileReader()
      // Set the image once loaded into file reader
      reader.onload = (e) => {
        img.src = e.target.result
        img.onload = () => {
          const MAX_WIDTH = 1024
          const MAX_HEIGHT = 768
          let { width, height } = img

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(Utils.canvasToBlob(canvas, file.type))
        } // img.onload
      }
      reader.readAsDataURL(file)
    })
  }

  static canvasToBlob(canvas, type, quality) {
    const blobBin = atob(canvas.toDataURL(type, quality).split(',')[1])
    const dataArray = new Uint8Array(blobBin.length)
    for (let i = 0; i < blobBin.length; i += 1) {
      dataArray[i] = blobBin.charCodeAt(i)
    }
    return new File([dataArray], { type: type || 'image/png' })
  }

  static sequencePromise(tasks) {
    return tasks.reduce((previous, task) => previous.then(task), Promise.resolve())
  }

  static countWordings(wordings) {
    const countRegex = new RegExp(
      '[A-Za-z0-9_]+|[\u30A0-\u30FF\u3040-\u309F\u4E00-\u9FFF\uF900-\uFAFF\u3400-\u4DBF]', // ASCII letters (no accents) // Single CJK ideographs
      'g',
    )
    const matches = wordings.match(countRegex)
    return matches ? matches.length : 0
  }
}
