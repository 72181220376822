import React from 'react'
import { find } from 'lodash'
import { object, array, bool } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NotFound } from '@client/components/index'
import actions from '@client/actions'

const CheckCompetitionExist = (Component) => {
  const mapStateToProps = (state) => ({
    navigatorCompetitionList: state.navigatorCompetitionList.data,
    isFetchDone: state.navigatorCompetitionList.done,
  })
  const mapDispatchToProps = (dispatch) => {
    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class extends React.Component {
      static propTypes = {
        match: object.isRequired,
        navigatorCompetitionList: array.isRequired,
        isFetchDone: bool.isRequired,
      }

      render() {
        const { navigatorCompetitionList, isFetchDone } = this.props
        let foundCompetition = {}
        if (isFetchDone) {
          foundCompetition = find(navigatorCompetitionList, (competition) => {
            return competition.id === this.props.match.params.competitionId
          })
          if (!foundCompetition) return <NotFound title="Competition" />
        }

        return <Component {...this.props} competition={foundCompetition} />
      }
    },
  )
}

export default CheckCompetitionExist
