import { types } from '@client/actions/competition'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const competition = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_COMPETITION:
    case types.FETCH_READ_COMPETITION:
    case types.FETCH_UPDATE_COMPETITION:
    case types.FETCH_DELETE_COMPETITION: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_COMPETITION:
    case types.SET_READ_COMPETITION:
    case types.SET_UPDATE_COMPETITION:
    case types.SET_DELETE_COMPETITION: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}
const competitionList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_READ_COMPETITION_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_COMPETITION_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_COMPETITION_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  competition,
  competitionList,
}
