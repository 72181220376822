import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CHECK_LOGIN: 'FETCH_CHECK_LOGIN',
  SET_CHECK_LOGIN: 'SET_CHECK_LOGIN',

  FETCH_LOGIN: 'FETCH_LOGIN',
  SET_LOGIN: 'SET_LOGIN',

  FETCH_LOGOUT: 'FETCH_LOGOUT',
  SET_LOGOUT: 'SET_LOGOUT',

  FETCH_UPDATE_PASSWORD: 'FETCH_UPDATE_PASSWORD',
  SET_UPDATE_PASSWORD: 'SET_UPDATE_PASSWORD',

  FETCH_FORGET_PASSWORD: 'FETCH_FORGET_PASSWORD',
  SET_FORGET_PASSWORD: 'SET_FORGET_PASSWORD',
}

export default {
  checkLogin: () => (dispatch) => {
    return request.get({ url: '/auth/check', type: types.SET_CHECK_LOGIN, dispatch })
  },

  login: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_LOGIN })
    return request.post({ url: '/auth/login', type: types.SET_LOGIN, dispatch, data })
  },

  logout: () => (dispatch) => {
    dispatch({ type: types.FETCH_LOGOUT })
    return request.post({ url: '/auth/logout', type: types.SET_LOGOUT, dispatch })
  },

  updatePassword: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_PASSWORD })
    return request.patch({
      url: '/auth/updatePassword',
      type: types.SET_UPDATE_PASSWORD,
      dispatch,
      data,
    })
  },

  forgetPassword: (data) => (dispatch) => {
    dispatch({ type: types.FETCH_FORGET_PASSWORD })
    return request.post({
      url: '/auth/forgetPassword',
      type: types.SET_FORGET_PASSWORD,
      dispatch,
      data,
    })
  },
}
