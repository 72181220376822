import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_HOST: 'FETCH_CREATE_HOST',
  SET_CREATE_HOST: 'SET_CREATE_HOST',

  FETCH_READ_HOST: 'FETCH_READ_HOST',
  SET_READ_HOST: 'SET_READ_HOST',

  FETCH_UPDATE_HOST: 'FETCH_UPDATE_HOST',
  SET_UPDATE_HOST: 'SET_UPDATE_HOST',

  FETCH_DELETE_HOST: 'FETCH_DELETE_HOST',
  SET_DELETE_HOST: 'SET_DELETE_HOST',

  FETCH_READ_HOST_LIST: 'FETCH_READ_HOST_LIST',
  SET_READ_HOST_LIST: 'SET_READ_HOST_LIST',

  FETCH_TEST_HOST_MAILER: 'FETCH_TEST_HOST_MAILER',
  SET_TEST_HOST_MAILER: 'SET_TEST_HOST_MAILER',

  RESET_HOST: 'RESET_HOST',
  RESET_HOST_LIST: 'RESET_HOST_LIST',
}

export default {
  addHost: (competitionId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_HOST })
    return request.post({
      url: `/competition/${competitionId}/host`,
      type: types.SET_CREATE_HOST,
      dispatch,
      data,
    })
  },

  getHost: (competitionId, hostId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_HOST })
    return request.get({
      url: `/competition/${competitionId}/host/${hostId}`,
      type: types.SET_READ_HOST,
      dispatch,
    })
  },

  updateHost: (competitionId, hostId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_HOST })
    return request.patch({
      url: `/competition/${competitionId}/host/${hostId}`,
      type: types.SET_UPDATE_HOST,
      dispatch,
      data,
    })
  },

  deleteHost: (competitionId, hostId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_HOST })
    return request.delete({
      url: `/competition/${competitionId}/host/${hostId}`,
      type: types.SET_DELETE_HOST,
      dispatch,
    })
  },

  getHostList: (competitionId, params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_HOST_LIST })
    return request.get({
      url: `/competition/${competitionId}/host`,
      type: types.SET_READ_HOST_LIST,
      dispatch,
      params,
    })
  },

  testHostMailer: (competitionId, hostId) => (dispatch) => {
    dispatch({ type: types.FETCH_TEST_HOST_MAILER })
    return request.post({
      url: `/competition/${competitionId}/host/${hostId}/testMailer`,
      type: types.SET_TEST_HOST_MAILER,
      dispatch,
    })
  },

  resetHost: () => (dispatch) => {
    return dispatch({ type: types.RESET_HOST })
  },

  resetHostList: () => (dispatch) => {
    return dispatch({ type: types.RESET_HOST_LIST })
  },
}
