import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NoPermission } from '@client/components/index'
import actions from '@client/actions'
import ROLE from '@server/constants/roles'

const CheckPermission = ({ checkCompetition, checkHost }) => (Component) => {
  const mapStateToProps = (state) => ({
    auth: state.auth.data,
  })
  const mapDispatchToProps = (dispatch) => {
    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class extends React.Component {
      static propTypes = {
        match: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
      }

      render() {
        const {
          auth,
          match: {
            params: { hostId, competitionId },
          },
        } = this.props

        if (
          auth.role !== ROLE.ADMIN &&
          ((checkHost && (auth.competition_id !== competitionId || auth.host_id !== hostId)) ||
            (checkCompetition && auth.competition_id !== competitionId))
        ) {
          return <NoPermission title="Competition" />
        }

        return <Component {...this.props} />
      }
    },
  )
}

export default CheckPermission
