const BaseImageFormat = window.Quill.import('formats/image')
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style']

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce((formats, attribute) => {
      const cloneFormats = { ...formats }
      if (domNode.hasAttribute(attribute)) {
        cloneFormats[attribute] = domNode.getAttribute(attribute)
      }
      return cloneFormats
    }, {})
  }

  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

export default ImageFormat
