import _ from 'lodash'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export const I18N_LANG_LIST = [
  {
    lang: 'zh-TW',
    name: '繁體中文',
  },
  {
    lang: 'zh-CN',
    name: '简体中文',
  },
  {
    lang: 'en-US',
    name: 'English',
  },
  {
    lang: 'ja-JP',
    name: 'にほんご',
  },
]

export const i18nConfig = {
  fallbackLng: false,
  whitelist: _.map(I18N_LANG_LIST, 'lang'),
  load: 'currentOnly',
  debug: false,
  detection: {
    caches: ['cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: false,
    useSuspense: false,
  },
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nConfig)

export default i18n
