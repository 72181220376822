export const types = {
  INCREASE_REQUEST: 'INCREASE_REQUEST',
  DECREASE_REQUEST: 'DECREASE_REQUEST',
}

export default {
  increaseRequest: () => (dispatch) => {
    return dispatch({ type: types.INCREASE_REQUEST })
  },
  decreaseRequest: () => (dispatch) => {
    return dispatch({ type: types.DECREASE_REQUEST })
  },
}
