import { types } from '@client/actions/loadingMask'

const INIT_STATE = {
  data: {
    type: '',
    isEnable: true,
  },
}

const loadingMask = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SET_LOADING_MASK: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default { loadingMask }
