import request from '@client/lib/ApiClient'

export const types = {
  FETCH_CREATE_DRAFT: 'FETCH_CREATE_DRAFT',
  SET_CREATE_DRAFT: 'SET_CREATE_DRAFT',

  FETCH_READ_DRAFT: 'FETCH_READ_DRAFT',
  SET_READ_DRAFT: 'SET_READ_DRAFT',

  FETCH_UPDATE_DRAFT: 'FETCH_UPDATE_DRAFT',
  SET_UPDATE_DRAFT: 'SET_UPDATE_DRAFT',

  FETCH_DELETE_DRAFT: 'FETCH_DELETE_DRAFT',
  SET_DELETE_DRAFT: 'SET_DELETE_DRAFT',

  FETCH_READ_DRAFT_LIST: 'FETCH_READ_DRAFT_LIST',
  SET_READ_DRAFT_LIST: 'SET_READ_DRAFT_LIST',

  RESET_DRAFT: 'RESET_DRAFT',
  RESET_DRAFT_LIST: 'RESET_DRAFT_LIST',
}

export default {
  addDraft: (userId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_CREATE_DRAFT })
    return request.post({
      type: types.SET_CREATE_DRAFT,
      url: `/user/${userId}/draft`,
      dispatch,
      data,
    })
  },

  getDraft: (userId, draftId) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_DRAFT })
    return request.get({
      url: `/user/${userId}/draft/${draftId}`,
      type: types.SET_READ_DRAFT,
      dispatch,
    })
  },

  updateDraft: (userId, draftId, data) => (dispatch) => {
    dispatch({ type: types.FETCH_UPDATE_DRAFT })
    return request.patch({
      url: `/user/${userId}/draft/${draftId}`,
      type: types.SET_UPDATE_DRAFT,
      dispatch,
      data,
    })
  },

  deleteDraft: (userId, draftId) => (dispatch) => {
    dispatch({ type: types.FETCH_DELETE_DRAFT })
    return request.delete({
      url: `/user/${userId}/draft/${draftId}`,
      type: types.SET_DELETE_DRAFT,
      dispatch,
    })
  },

  getDraftList: (userId, params) => (dispatch) => {
    dispatch({ type: types.FETCH_READ_DRAFT_LIST })
    return request.get({
      url: `/user/${userId}/draft`,
      type: types.SET_READ_DRAFT_LIST,
      dispatch,
      params,
    })
  },

  resetDraft: () => (dispatch) => {
    return dispatch({ type: types.RESET_DRAFT })
  },

  restDraftList: () => (dispatch) => {
    return dispatch({ type: types.RESET_DRAFT_LIST })
  },
}
