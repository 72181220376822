import React from 'react'
import { string, object, node } from 'prop-types'

import { Navigation } from '@client/containers/index'
import { Footer } from '@client/components/index'

class AppContainer extends React.Component {
  static propTypes = {
    style: object,
    children: node,
    className: string,
  }

  static defaultProps = {
    style: {},
    children: null,
    className: '',
  }

  componentWillUnmount() {
    window.SSR = false
  }

  render() {
    const { style, children, className } = this.props

    return (
      <>
        <Navigation />
        <div
          className={className}
          style={{
            minHeight: 'calc( 100vh - 120px )', // 120px is height of footer
            paddingTop: '120px',
            ...style,
          }}
        >
          {children}
        </div>
        <Footer />
      </>
    )
  }
}

export default AppContainer
