const errorMessages = {
  INTERNAL_SERVER_ERROR: {
    status: 500,
    data: { code: '500001', message: 'Internal server error' },
  },
  MODEL_NOT_FOUND: {
    status: 500,
    data: { code: '500002', message: 'Model not found' },
  },
  MODEL_METHOD_NOT_IMPLEMENT: {
    status: 500,
    data: { code: '500003', message: 'Model method not implement' },
  },
  SEND_MAIL_FAILURE: {
    status: 500,
    data: { code: '500004', message: 'Send mail failure' },
  },

  INVALID_FIELDS: {
    status: 400,
    data: { code: '400001', message: 'Invalid fields' },
  },
  MISSING_REQUIRED_PARAMS: {
    status: 400,
    data: { code: '400002', message: 'Missing required parameters' },
  },

  AUTHENTICATION_FAILED: {
    status: 401,
    data: { code: '401001', message: 'Authentication failed' },
  },

  CHECK_AUTHENTICATION_FAILED: {
    status: 401,
    data: { code: '401002', message: 'Check Authentication failed' },
  },

  FORBIDDEN: {
    status: 403,
    data: { code: '403001', message: 'Forbidden' },
  },

  INVALID_DATE_DURATION: {
    status: 403,
    data: { code: '403002', message: 'Invalid date duration' },
  },

  ALREADY_VOTED: {
    status: 403,
    data: { code: '403003', message: 'Already voted' },
  },

  API_NOT_FOUND: {
    status: 404,
    data: { code: '404001', message: 'API not found' },
  },
  PAGE_NOT_FOUND: {
    status: 404,
    data: { code: '404002', message: 'Page not found' },
  },
  USER_NOT_FOUND: {
    status: 404,
    data: { code: '404003', message: 'User not found' },
  },
  RESOURCE_NOT_FOUND: {
    status: 404,
    data: { code: '404004', message: 'Resource not found' },
  },
  CANCEL_REQUEST: {
    status: 45,
    data: { code: '45001', message: 'Request canceled' },
  },
}

// for auto complete
// const errors = {
//   INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
//   MODEL_NOT_FOUND: 'MODEL_NOT_FOUND',
//   MODEL_METHOD_NOT_IMPLEMENT: 'MODEL_METHOD_NOT_IMPLEMENT',

//   INVALID_FIELDS: 'INVALID_FIELDS',
//   MISSING_REQUIRED_PARAMS: 'MISSING_REQUIRED_PARAMS',

//   AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',

//   FORBIDDEN: 'FORBIDDEN',

//   USER_NOT_FOUND: 'USER_NOT_FOUND',
//   API_NOT_FOUND: 'API_NOT_FOUND',
// };

const errors = Object.keys(errorMessages).reduce((previous, current) => {
  const clonePrevious = { ...previous }
  clonePrevious[current] = current
  return clonePrevious
}, {})

export { errors, errorMessages }
