import { types } from '@client/actions/resetPassword'
import * as loadingStatus from '@client/constants/loadingStatus'

const INIT_STATE = {
  data: {},
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}

const resetPassword = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CREATE_RESET_PASSWORD:
    case types.FETCH_READ_RESET_PASSWORD:
    case types.FETCH_UPDATE_RESET_PASSWORD:
    case types.FETCH_DELETE_RESET_PASSWORD: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_CREATE_RESET_PASSWORD:
    case types.SET_READ_RESET_PASSWORD:
    case types.SET_UPDATE_RESET_PASSWORD:
    case types.SET_DELETE_RESET_PASSWORD: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

const LIST_INIT_STATE = {
  data: [],
  total: 0,
  error: {},
  loadingStatus: loadingStatus.EMPTY,
  done: false,
}
const resetPasswordList = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case types.READ_RESET_PASSWORD_LIST: {
      return {
        ...state,
        loadingStatus: loadingStatus.LOADING,
      }
    }

    case types.SET_READ_RESET_PASSWORD_LIST: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case types.RESET_RESET_PASSWORD_LIST: {
      return {
        ...state,
        ...LIST_INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default {
  resetPassword,
  resetPasswordList,
}
