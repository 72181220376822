import request from '@client/lib/ApiClient'

export const types = {
  FETCH_UPLOAD_TO_S3: 'FETCH_UPLOAD_TO_S3',
  SET_UPLOAD_TO_S3: 'SET_UPLOAD_TO_S3',
}

export default {
  uploadToS3: ({ file, signedUrl, url, onUploadProgress }) => (dispatch) => {
    dispatch({ type: types.FETCH_UPLOAD_TO_S3 })
    return request.put({
      data: file,
      headers: {
        'Content-type': file.type,
      },
      rawUrl: signedUrl,
      url,
      type: types.SET_UPLOAD_TO_S3,
      dispatch,
    })
  },
}
