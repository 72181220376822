import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Rate, Tag } from 'antd'
import { withTranslation } from 'react-i18next'

import { QuillContent, TextShowMore, AspectRatioImage } from '@client/components/index'
import { IMAGE_RATIOS } from '@client/constants/index'

import styles from './TaskCard.styl'

const CardRuleInfo = ({ title, rule, color, unit }) => (
  <div className={styles.cardRuleInfo} style={{ border: `1px solid ${color}` }}>
    <div className={styles.cardRuleContent}>
      {rule}
      {unit && <span className={styles.unit}>{unit}</span>}
    </div>
    <div className={styles.cardRuleInfoTitle} style={{ color }}>
      {title}
    </div>
  </div>
)

CardRuleInfo.propTypes = {
  title: PropTypes.string,
  rule: PropTypes.node,
  color: PropTypes.string,
  unit: PropTypes.string,
}

CardRuleInfo.defaultProps = {
  title: '',
  rule: null,
  color: '',
  unit: '',
}

class Task extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    hideCover: PropTypes.bool,
    footer: PropTypes.node,
  }

  static defaultProps = {
    hideCover: false,
    footer: null,
  }

  render() {
    const { task, footer, hideCover, t } = this.props
    // TODO: TASK COLOR
    const { color } = {}

    return (
      <div className={styles.cardContainer}>
        <div className={styles.card} style={{ borderBottom: `6px solid ${color}` }}>
          {!hideCover && <AspectRatioImage src={task.cover} ratio={IMAGE_RATIOS.TASK} />}

          <div className={styles.content}>
            <Row type="flex" justify="center" gutter={10}>
              <Tag>{t(task.category)}</Tag>
            </Row>
            <div className={styles.title} style={{ color }}>
              {task.name}
            </div>
            <div className={styles.score}>
              {task.is_scorable ? t('彈性評分') : <Rate disabled value={task.score} />}
            </div>

            <TextShowMore content={task.description} length={50} />

            <Row type="flex" justify="center" gutter={10}>
              <Col span={12}>
                <CardRuleInfo title={t('執行人數')} rule={task.person_rule} color={color} />
              </Col>
              <Col span={12}>
                <CardRuleInfo
                  title={t('照片張數規定')}
                  rule={task.photo_rule}
                  color={color}
                  unit={t('張')}
                />
              </Col>
              <Col span={12}>
                <CardRuleInfo
                  title={t('字數規定')}
                  rule={task.word_rule}
                  color={color}
                  unit={t('字')}
                />
              </Col>
              <Col span={12}>
                <CardRuleInfo
                  title={t('影片長度規定')}
                  rule={task.video_rule}
                  color={color}
                  unit={t('秒')}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={10}>
              <Col span={24}>
                <CardRuleInfo
                  title={t('其他規則')}
                  rule={
                    <TextShowMore
                      className={styles.otherRule}
                      content={<QuillContent content={task.other_rule} />}
                      length={50}
                    />
                  }
                  color={color}
                />
              </Col>
            </Row>
            {footer && (
              <Row type="flex" justify="center">
                <Col span={24}>{footer}</Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Task)
